import React from 'react';
import IntlMessages from 'helpers/IntlMessages';
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'pt';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
  { id: 'pt', name: 'Português - Brasil', direction: 'ltr' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.coloredstrategies.com';
export const buyUrl = 'https://www.litroz.com/solicitarnovacompra';
export const buyByPaymentSlipUrl = 'https://www.litroz.com/boleto';
export const buyByCreditTranferUrl = 'https://www.litroz.com/transferencia';

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false;
export const defaultColor = 'light.blue';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = false;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;

export const defaultPageSizes = [5, 10, 25, 50, 100];

export const TRANSACTION_PASS_MAX_LENGTH = 4;

export const DRIVERS_SPREADSHEET_EXAMPLE_URL =
  'https://docs.google.com/spreadsheets/d/1f90nX3HNMIk1zDt86UHv9R3J-Cm3gfyh/export?format=xlsx';

export const VEHICLES_SPREADSHEET_EXAMPLE_URL =
  'https://docs.google.com/spreadsheets/d/1bNRwHRQTSe--kYGGQQ0TwTX1jiE-jXRW/export?format=xlsx';

export const billDateOptions = [
  // { label: <IntlMessages id="pages.weekly" />, value: () => '07', key: 1 },
  { label: <IntlMessages id="pages.fortnightly" />, value: () => '15', key: 2 },
  {
    label: <IntlMessages id="pages.monthly" />,
    value: (month, year) => new Date(year, month, 0).getDate(),
    key: 3,
  },
];

export const PAYMENT_METHOD_FREQUENCY_NAMES = {
  WEEKLY: 'weekly',
  FORTNIGHTLY: 'fortnightly',
  MONTHLY: 'monthly',
};

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted',
};

export const TYPE_FUEL = {
  GASOLINA: 'gas',
  ETANOL: 'ethanol',
  FLEX: 'flex',
  DIESEL: 'diesel',
  DIESELS500: 'dieselS500',
  'GÁS NATURAL VEICULAR': 'carrierNaturalGas',
};

export const STATUS_PURCHASE = {
  PENDING: 'data.pending',
  PAID: 'data.paid',
  CANCELLED: 'data.cancelled',
};

export const STATUS_TYPES_PURCHASE = [
  {
    label: <IntlMessages id={STATUS_PURCHASE.PENDING} />,
    value: 'pending',
    key: 1,
  },
  {
    label: <IntlMessages id={STATUS_PURCHASE.PAID} />,
    value: 'paid',
    key: 2,
  },
  {
    label: <IntlMessages id={STATUS_PURCHASE.CANCELLED} />,
    value: 'cancelled',
    key: 3,
  },
];

export const PAYMENT_TYPE = {
  PIX: 'pages.payment-type-pix',
  BILL: 'pages.payment-type-bill',
  TRANSFER: 'pages.payment-type-transfer',
};

export const STATUS_TRANSACTIONS = {
  APROVED: 'data.approved',
  PENDING: 'data.pending',
  CANCELLED: 'data.cancelled',
};

export const STATUS_TYPES = [
  { label: <IntlMessages id="active" />, value: true, key: 1 },
  { label: <IntlMessages id="inactive" />, value: false, key: 2 },
  { label: <IntlMessages id="deleted" />, value: 'isDeleted', key: 3 },
];

export const TRANSACTIONS_STATUS_TYPES = [
  {
    label: <IntlMessages id={STATUS_TRANSACTIONS.APROVED} />,
    value: 'active',
    key: 1,
  },
  {
    label: <IntlMessages id={STATUS_TRANSACTIONS.CANCELLED} />,
    value: 'inactive',
    key: 2,
  },
];

export const LITROZ_TRANSACTION = 'litrozTransaction';

export const FUEL_MANAGEMENT_TRANSACTION = 'fuelManagementTransaction';

export const TRANSACTION_TYPES = [
  {
    label: <IntlMessages id="transaction.litroz" />,
    value: LITROZ_TRANSACTION,
    key: 1,
  },
  {
    label: <IntlMessages id="transaction.fuel-management" />,
    value: FUEL_MANAGEMENT_TRANSACTION,
    key: 2,
  },
];

export const FUEL_TYPES = [
  { label: <IntlMessages id="fuel.ethanol" />, value: 'ethanol', key: 1 },
  {
    label: <IntlMessages id="fuel.premiumEthanol" />,
    value: 'premiumEthanol',
    key: 2,
  },
  { label: <IntlMessages id="fuel.diesel" />, value: 'diesel', key: 3 },
  {
    label: <IntlMessages id="fuel.dieselS500" />,
    value: 'dieselS500',
    key: 4,
  },
  {
    label: <IntlMessages id="fuel.carrierNaturalGas" />,
    value: 'carrierNaturalGas',
    key: 5,
  },
  { label: <IntlMessages id="fuel.gas" />, value: 'gas', key: 6 },
  {
    label: <IntlMessages id="fuel.premiumGas" />,
    value: 'premiumGas',
    key: 7,
  },
  { label: <IntlMessages id="fuel.arla" />, value: 'arla', key: 8 },
];

export const VEHICLE_FUEL_TYPES = [
  { label: 'Gasolina', value: 'gas', key: 0 },
  { label: 'Etanol', value: 'ethanol', key: 1 },
  { label: 'Flex', value: 'flex', key: 2 },
  { label: 'DieselS10', value: 'diesel', key: 3 },
  { label: 'DieselS500', value: 'dieselS500', key: 4 },
  { label: 'Gás Natural Veicular', value: 'carrierNaturalGas', key: 5 },
  {
    label: "GNV + Flex",
    value: 'carrierNaturalGasFlex',
    key: 6,
  }
];

export const userCategories = [
  { label: <IntlMessages id="pages.driver" />, value: 'employee', key: 1 },
  { label: <IntlMessages id="pages.recipient" />, value: 'contractor', key: 2 },
];

export const VEHICLE_MODAL_DATA = {
  licensePlate: '',
  make: '',
  model: '',
  year: '',
  color: '',
  tank: '',
  efficiency: '',
  odometer: '',
  fuel: VEHICLE_FUEL_TYPES[0],
  tag: '',
};

export const CONTAINER_MODAL_DATA = {
  licensePlate: '',
  tank: '',
  make: '',
  model: '',
  odometer: '',
  fuel: VEHICLE_FUEL_TYPES[0],
};

export const EXPENSE_CENTER_DATA = {
  name: '',
  description: '',
};

export const BRANCH_MODAL_DATA = {
  federalTaxNumber: '',
  name: '',
  tradeName: '',
  email: '',
  address: '',
  number: '',
  neighborhood: '',
  postalCode: '',
  city: '',
  state: '',
  photoUrl: '',
};

export const DEBOUNCE_TIME = 1000;

export const DEFAULT_PAGE_SIZE = 10;

export const INITIAL_PAGE = 1;

export const BALANCE_SHARING_TYPE = {
  VEHICLE: 'vehicle',
  EMPLOYEE: 'employee',
};

export const VEHICLE_BALANCE_COLUMN_INDEX = 7;

export const DRIVER_BALANCE_COLUMN_INDEX = 4;

export const CONTAINER_BALANCE_COLUMN_INDEX = 7;

export const RECIPIENT_BALANCE_COLUMN_INDEX = 4;

export const COLUMNS_TO_BE_REMOVED = 1;

export const CURRENCY_CONFIG = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const TRANSACTION_LIMIT_VALUE = 5000;

export const SHARED_BALANCE_DEFAULT_DISPLAY = 1999;

export const BALANCE_SHARING_INPUTS = [
  {
    label: 'input.employee',
    id: 'employee',
    defaultChecked: true,
    value: 'employee',
  },
  {
    label: 'input.vehicle',
    id: 'vehicle',
    defaultChecked: false,
    value: 'vehicle',
  },
];

export const RESET_SORT_COUNT = 3;

export const PASSWORD = 'password';

export const DELETED_KEY = 'isDeleted';
